<template>
  <a-modal :visible="visible" :width="800" title="选择关联任务" destroy-on-close @cancel="() => $emit('cancel')">
    <div slot="footer">
      <a-button @click="() => $emit('cancel')">取消</a-button>
      <a-button type="primary" :disabled="selectedRowKeys.length===0" @click="handleOk">确定</a-button>
    </div>
    <div class="search-box">
      <a-input v-model="filter.taskName" class="search-input" allowClear />
      <a-button type="primary" @click="onSearch">搜索</a-button>
    </div>
    <a-table 
      :columns="selectTaskColumns"
      :data-source="taskList"
      :pagination="false"
      :loading="tableLoading"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, getCheckboxProps: getCheckboxProps }"
      :row-key="record => record.id"
      :scroll="{ y: 300 }"
    />
  </a-modal>
</template>

<script>
import { selectTaskColumns } from '../constants'
import { getShopTaskList } from '@/request/api/marketingCenter'
import { addTaskConfigAPI } from '@/request/api/operateCenter'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    ids: {
      type: Array,
      default: []
    }
  },
  watch: {
    visible(val) {
      this.selectedRowKeys = []
    }
  },
  data() {
    return {
      filter: {
        taskName: '',
        state: 1
      },
      selectTaskColumns,
      tableLoading: true,
      selectedRowKeys: [],
      taskList: []
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.tableLoading = true
      const { code, data } = await getShopTaskList(this.filter)
      this.tableLoading = false
      if (code !== 200 ) return
      this.taskList = data
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    getCheckboxProps(record) {
      return {
        props: {
          disabled: this.ids.includes(record.id)
        }
      }
    },
    paginationCallBack(current, pageSize) {
      this.fetchData()
    },
    async handleOk() {
      const params = {
        taskId: this.selectedRowKeys
      }
      const { data, code } = await addTaskConfigAPI(params)
      if (code !== 200) return
      this.$message.success('添加成功')
      this.$emit('ok')
    },
    onSearch() {
      this.fetchData()
    }
  }
}
</script>

<style lang="less" scoped>
.search-box {
  .search-input {
    width: 200px;
    margin-right: 20px;
  }
}
</style>