export const TaskListColumns = [
  {
    title: '积分获取行为',
    dataIndex: 'taskName',
    key: 'taskName',
  },
  {
    title: '积分获取数量',
    dataIndex: 'number',
    key: 'number',
    scopedSlots: { customRender: 'number' },
  },
  {
    title: '状态',
    dataIndex: 'state',
    key: 'state',
    scopedSlots: { customRender: 'state' },
  },
  {
    title: '操作',
    key: 'action',
    dataIndex: 'action',
    width: '5%',
    scopedSlots: { customRender: 'action' },
  }
]

export const selectTaskColumns = [
  {
    title: '任务名称',
    dataIndex: 'taskName',
    key: 'taskName',
  },
  {
    title: '任务分类',
    dataIndex: 'taskType',
    key: 'taskType',
  },
  {
    title: '任务周期',
    dataIndex: 'taskCycle',
    key: 'taskCycle',
  },
  {
    title: '奖励周期',
    dataIndex: 'rewardCycle',
    key: 'rewardCycle',
  }
]

export const UserIntegralListColumns = [
  {
    title: '手机号',
    dataIndex: 'userName',
    key: 'userName',
  },
  {
    title: '用户姓名',
    dataIndex: 'nickName',
    key: 'nickName',
  },
  {
    title: '所属渠道',
    dataIndex: 'agentName',
    key: 'agentName',
  },
  {
    title: '已消耗积分',
    key: 'totalUse',
    dataIndex: 'totalUse',
  },
  {
    title: '可用积分',
    key: 'total_credit',
    dataIndex: 'totalCredit',
    sorter: true,
  },
  {
    title: '积分状态',
    dataIndex: 'status',
    key: 'status',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '操作',
    key: 'actions',
    dataIndex: 'actions',
    width: 200,
    scopedSlots: { customRender: 'actions' },
  }
]

export const IntegralDetailColumns = [
  {
    title: '操作时间',
    dataIndex: 'createTime',
    width: 200
  },
  {
    title: '操作人',
    dataIndex: 'adminer',
  },
  {
    title: '操作类型',
    dataIndex: 'actionType',
    scopedSlots: { customRender: 'actionType' },
  },
  {
    title: '操作前可用积分',
    dataIndex: 'numBeforeAct',
  },
  {
    title: '操作后可用积分',
    dataIndex: 'numAfterAct',
  },
  {
    title: '描述',
    dataIndex: 'reason',
    width: 350
  }
]