/*
 * @Description: 运营中心
 */
// 运营中心
import request from '@/request/request'

// 积分获取配置
// 添加运营任务
export const addTaskConfigAPI = (params) => request.post('/adminv2/shoptask/addTaskConfig', params)
// 运营任务列表
export const taskConfigListsAPI = (params) => request.post('/adminv2/shoptask/taskConfigLists', params)
// 编辑
export const editTaskConfigAPI = (params) => request.post('/adminv2/shoptask/editTaskConfig', params)
// 用户积分列表
export const getCreditListAPI = (params) => request.post('/adminv2/credit/list', params)
// 修改积分状态
export const updateCreditStatusAPI = (params) => request.post('/adminv2/credit/status', params)
// 可用积分操作记录
export const creditChangeRecordAPI = (params) => request.post('/adminv2/credit/changeRecord', params)
// 修改积分数量
export const creditChangeAPI = (params) => request.post('/adminv2/credit/change', params)

