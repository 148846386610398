<template>
  <div class="padding24 color_white">
    <div class="head-box">
      <a-alert message="积分获取行为及数值配置" type="info" />
      <a-button v-if="operation.add" type="primary" @click="taskListModal = true">添加选择任务</a-button>
    </div>
    <a-table
      :columns="TaskListColumns"
      :data-source="tabledData"
      :pagination="false"
      :loading="tableLoading"
      :row-key="record => record.id"
    >
      <span slot="number" slot-scope="text, record">
        <span v-if="+record.creditType === 1">{{ record.credit }}</span>
        <span v-else>{{ record.creditStart }}~{{ record.creditEnd }}</span>
      </span>
      <span slot="state" slot-scope="text, record">{{+record.state === 1 ? '启用' : '禁用'}}</span>
      <span slot="action" slot-scope="text, record">
        <a v-if="operation.edit" @click="editClick(record)">编辑</a>
      </span>
    </a-table>
    <!-- <MyPagination
      :count="total"
      :pageNo="pageNo"
      @showSizeChangeFn="paginationCallBack"
      v-show="!tableLoading"
    /> -->
    <IntegralList :visible="taskListModal" :ids="selectIds" @ok="selectTask" @cancel="taskListModal = false" />
    <EditIntegralConfig :visible="editModal" :record="curRecord" @ok="onEdit" @cancel="editModal = false" />
  </div>
</template>

<script>
import { TaskListColumns } from './constants'
import MyPagination from '@/components/pagination/MyPagination'
import IntegralList from './components/IntegralList'
import EditIntegralConfig from './components/editIntegralConfig'
import { taskConfigListsAPI } from '@/request/api/operateCenter'
import { codeFn } from '@/utils/tools'
export default {
  name: 'IntegralConfig',
  components: { MyPagination, IntegralList, EditIntegralConfig },
  data() {
    return {
      operation: {
        edit: false,
        disabled: false
      },
      tableLoading: false,
      TaskListColumns,
      tabledData: [],
      total: 0,
      pageNo: 1,
      pageSize: 20,
      taskListModal: false,
      editModal: false,
      curRecord: {},
      selectIds: []
    }
  },
  created() {
    this.operation.add = !!codeFn('addIntegralConfig')
    this.operation.edit = !!codeFn('editIntegralConfig')
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.tableLoading = true
      const params = {
        pageNo: this.pageNo,
        pageSize: 100 
      }
      const { code, data } = await taskConfigListsAPI(params)
      this.tableLoading = false
      if (code !== 200) return
      this.tabledData = data.data
      this.total = data.count
      this.selectIds = data.data.map(item => item.taskId)
    },
    selectTask() {
      this.pageNo = 1
      this.fetchData()
      this.taskListModal = false
    },
    paginationCallBack(current, pageSize) {
      this.pageNo = current
      this.pageSize = pageSize
      this.fetchData()
    },
    editClick(record) {
      this.curRecord = Object.assign({}, record)
      this.editModal = true
    },
    onEdit() {
      this.editModal = false
      this.pageNo = 1
      this.fetchData()
    }
  }
}
</script>

<style lang="less" scoped>
.head-box {
  display: flex;
  justify-content: space-between;
}
</style>