<template>
  <div>
    <a-modal :visible="visible" title="修改积分获取配置" destroyOnClose @ok="handleOk" @cancel="onCancel">
      <a-form-model ref="ruleForm" :model="record" :rules="rules" labelAlign="right" :label-col="labelCol" :wrapper-col="wrapperCol" style="height: 350px; overflow:hidden; overflow-y:auto" >
        <a-form-model-item label="积分获取行为" prop="name">
          {{ record.taskName }}
        </a-form-model-item>
        <a-form-model-item label="积分获取数量" prop="count">
          <a-select v-model="record.creditType" style="width: 120px" @change="setCountVal">
            <a-select-option :value="1">固定积分</a-select-option>
            <a-select-option :value="2">随机积分</a-select-option>
          </a-select>
          <br>
          <a-input-number v-if="+record.creditType === 1" v-model="record.credit" :min="0" @change="setCountVal" />
          <div v-else style="display: flex; align-items: center">
            <a-input-number v-model="record.creditStart" :min="0" @change="setCountVal" />
            <span style="margin: 10px">——</span>
            <a-input-number v-model="record.creditEnd" :min="0" @change="setCountVal" />
          </div>
        </a-form-model-item>
        <a-form-model-item label="任务对应的值" prop="taskValue">
          <a-input v-model="record.taskValue" placeholder="请输入任务对应的值" />
        </a-form-model-item>
        <a-form-model-item label="生效版本号" prop="version">
          <a-input v-model="record.version" placeholder="请输入生效版本号" />
        </a-form-model-item>
        <a-form-model-item label="跳转方式" prop="targetType">
          <a-select
            v-model="record.targetType"
            style="width: 150px"
            allowClear
          >
            <a-select-option v-for="item in targetTypeList" :key="item.key"> {{ item.value }}</a-select-option>
          </a-select>
          <a-input v-model="record.targetValue" placeholder="请输入跳转参数" />
        </a-form-model-item>
        <a-form-model-item label="是否启用" prop="state" required>
          <a-radio-group v-model="record.state">
            <a-radio :value="1"> 是 </a-radio>
            <a-radio :value="2"> 否 </a-radio>
          </a-radio-group>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import { editTaskConfigAPI } from '@/request/api/operateCenter'
const targetTypeMap = new Map([
  [1, '观看直播'],
  [2, '邀请好友观看直播'],
  [3, '听指定的书'],
  [4, '邀请好友听书'],
  [5, '分享好书'],
  [6, '完善个人信息'],
  [7, '绑定微信'],
  [8, '邀请好友页面'],
])
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    record: {
      type: Object,
      default: ()  => ({})
    }
  },
  computed: {
    recordId() {
      return this.record.id
    }
  },
  watch: {
    visible(val) {
      if(!val) return
      this.setCountVal()
    }
  },
  data() {
    return {
      targetTypeMap,
      targetTypeList: [],
      selectVisible: false,
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      rules: {
        count: [{ required: true, message: "请设置合理的积分", min:1, type:'number', trigger: ['blur', 'change'] }],
        version: [{ required: true, message: '请输入生效版本号', trigger: ['blur', 'change'] }]
      }
    }
  },
  created() {
    this.targetTypeList = this.getMapToArr(targetTypeMap)
  },
  methods: {
    onCancel() {
      this.record = this.$options.propsData.record
      this.$emit('cancel')
    },
    getMapToArr(map) {
      const arr = [...map.entries()].reduce((item, [key, value]) => {
        item.push({ key: key, value: value })
        return item
      }, [])
      return arr
    },
    handleOk() {
      // 处理字段
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const params = {
            ...this.record
          }
          const { code } = await editTaskConfigAPI(params)
          if (code !== 200) return
          this.$message.success('修改成功')
          this.$emit('ok', this.record)
        }
      })
    },
    setCountVal() {
      const record = this.$options.propsData.record
      let count = 0
      if (this.record.creditType === 1) {
        count = this.record.credit
        this.record.creditStart = record.creditStart
        this.record.creditEnd = record.creditEnd
      } else {
        this.record.credit = record.credit
        count = this.record.creditStart === 0 || this.record.creditEnd === 0 || this.record.creditStart > this.record.creditEnd ? 0 : this.record.creditStart 
      }
      this.$set(this.record, 'count', count)
    }
  }
}
</script>